import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useHistory } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import Router from "./router";
import i18n from "./translation";
import Amplify, { API, Auth, Hub } from 'aws-amplify';
import awsconfig from '../src/aws-exports';
import { useState } from 'react';
import { AppContext } from './common/ContextLib';

Amplify.configure(awsconfig);




const StonedAge = () => {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const [accessToken, setAccessToken] = useState();
  const [group, setGroup] = useState("user");

  const history = useHistory();


  const navTo = (link) => {
    // console.log(link);
    history.push('/home');
    // window.location.reload('/');
  };

  API.configure({
    endpoints: [
      {
        name: "backend-api",
        endpoint: "https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/",
        //endpoint: "http://localhost:5000",
        service: "lambda",
        region: "ap-south-1",
        custom_header: async () => {
          return { Authorization: accessToken };
        },
      },
      {
        name: "test-api",
        // endpoint: "https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/",
        endpoint: "http://localhost:5000/api/",
        service: "lambda",
        region: "ap-south-1",
        custom_header: async () => {
          return { Authorization: accessToken };
        },
      },
    ],
  });

  // Storage.configure();
  useEffect(() => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          console.log('sign in');
          onLoad();
          navTo('home');
          break;
        case "signOut":
          setGroup("user");
          window.location.reload();
          break;
      }
    });
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      userHasAuthenticated(true);
      setAccessToken(token);

      const groups = (await Auth.currentSession()).getAccessToken().payload[
        "cognito:groups"
      ];

      // console.log((await Auth.currentSession()).getAccessToken().payload);


      // console.log(groups);

      if (groups && groups.length > 0 && groups.includes("admin")) {
        setGroup("admin");
      }

    } catch (e) {
      console.log(e);
      if (e !== "No current user") {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  };
  return (
    <BrowserRouter>
      {!isAuthenticating && (
        <AppContext.Provider value={{
          isAuthenticated,
          userHasAuthenticated,
          setAccessToken,
          group,
        }}>
          <I18nextProvider i18n={i18n}>
            <Router />
          </I18nextProvider>
        </AppContext.Provider>
      )}
    </BrowserRouter>
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

ReactDOM.render(<StonedAge />, document.getElementById("root"));
