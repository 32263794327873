import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './index.css';
import ReactDOM from 'react-dom';
import { Steps, Modal, Button, message, Form, Input, DatePicker, Space, Select, Row, Col, Divider, notification } from 'antd';
import axios from 'axios';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import useRazorpay from "react-razorpay";
import { useAppContext } from "../common/ContextLib";
import { Auth, API } from 'aws-amplify';
import moment from "moment";

const { Option } = Select;

// import Step1 from './Step1';

function DialogBox({ isVisible, setVisible }) {

  const [form] = Form.useForm();

  const [step, setStep] = React.useState(0);

  const [customerForm] = Form.useForm();

  const [finalData, setFinalData] = useState({});

  const [roomAvailable, setRoomAvailable] = useState([]);

  const [loading, setLoading] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState([1, 2]);

  const [categoryDate, setCategoryDate] = useState();

  const Razorpay = useRazorpay();

  const { isAuthenticated } = useAppContext();


  const saveDetails = () => {
    console.log(form.getFieldsValue());

    next();
  }

  useEffect(() => {
    console.log(finalData);
  }, [finalData])

  const next = () => {
    setFinalData(data => ({ ...data, ...form.getFieldsValue(), ...customerForm.getFieldsValue() }));
    setStep(step => step + 1);
  };

  const prev = () => {
    setFinalData(data => ({ ...data, ...form.getFieldsValue(), ...customerForm.getFieldsValue() }));
    setStep(step => step - 1);
  };

  const [emailSearchLoading, setEmailSearchLoading] = useState(false);

  const [categories, setCategories] = useState([]);

  const searchByEmail = (value) => {
    setEmailSearchLoading(true);
    console.log(value);
    axios.get("https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/customers", { params: { email: value } }).then(res => {
      setEmailSearchLoading(false);
      if (res.data.data.length > 0) {
        customerForm.setFieldsValue(res.data.data[0]);
        notification.open({
          message : "User information found",
          type: 'success'
        })
      } else {
        notification.open({
          message : "User information not found",
          type: 'warning'
        })
      }
      // console.log(customerForm.getFieldValue('id'));
      // setCategories(() => res.data.data);
    }, err => {
      setEmailSearchLoading(false);
      console.error(err);
    })
  }

  useEffect(() => {
    if (categories.length === 0) {
      // axios.get("https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/categories").then(res => {
        // console.log(res);
        axios.get("https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/categories").then( res => {
        setCategories(() => res.data.data);
      }, err => {
        console.error(err);
      })
    }

    if (isAuthenticated) {
      Auth.currentUserInfo().then(res => {
        searchByEmail(res.attributes.email)
      })
    }
  }, [])

  // const onFinishFailed = (errorInfo) => {
  //   console.log('Failed:', errorInfo);
  // };

  const { RangePicker } = DatePicker;

  const FirstStep = () => {




    const getRoomCount = (data) => {
      // setRoomsAvailable(1);
      setLoading(true);

      // axios.post("https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/categories/getcategory/ByDate", data).then(res => {
        axios.post("https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/rooms/getEmptyRoomsByDate", data).then( res => {
        setRoomAvailable(res.data.data)
        // setRoomAvailable(res.data.data);
        setLoading(false);

      }, err => {
        setLoading(false);
      })
    }



    const searchByCategory = () => {
      const values = form.getFieldsValue()
      const data = {
        categoryId: values.categoryId,
        startDate: values.checkInOutDate[0],
        endDate: values.checkInOutDate[1]
      }

      // console.log(values);
      getRoomCount(data);
      setCategoryDate({
        category: categories.find(item => item.id === values.categoryId),
        ...data
      });
    };

    const handleOnChange = () => {
      form.setFieldsValue({ 'rooms': ''})
      form.setFieldsValue({ 'checkInOutDate': ''})
      // roomAvailable(0);
      setCategoryDate(null)
    }

    useEffect(() => {
      console.log(categoryDate);
      // console.log(categoryDate?.category?.name);
    }, [categoryDate])

    const yesterday = moment().subtract(0, 'day');
    const disablePastDt = current => {
      return current.isBefore(yesterday);
    };

    return (
      <>
        <Form form={form} name="basic">
          <Row style={{ justifyContent: 'center' }} gutter={16}>
            <Col>
              <Form.Item name="categoryId" style={{ width: '168px' }}>
                <Select
                  style={{ width: 'max-content' }}
                  showSearch
                  placeholder="Select Category"
                  onChange={handleOnChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }

                >
                  {categories.map(item => <Option value={item.id}>{item.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="checkInOutDate">
                <RangePicker placeholder={['CheckIn Date', 'CheckOut Date']}  disabledDate={disablePastDt} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item><Button style={{ marginRight: '10px' }} type="primary" onClick={searchByCategory}>Search</Button></Form.Item>
            </Col>
          </Row>

          {roomAvailable.length > 0 && <Divider>{categoryDate?.category?.name} category selected</Divider>}
          <Form.List name="rooms" >
            {(rooms, { add, remove }) => {
              console.log(rooms);
              return (
                <div key={rooms}>
                  {rooms.map((room, index) => (
                    <Row gutter={16} key={index} style={{ justifyContent: 'center' }}>
                      <Col>
                        <Form.Item
                          name={[index, "id"]}
                          label="id"
                          initialValue={roomAvailable[index].id}
                          rules={[{ required: true }]} hidden
                        >
                          <Input placeholder="Room name" disabled />
                        </Form.Item>
                      </Col>

                      <Col>
                        <Form.Item
                          name={[index, "name"]}
                          label="Category"
                          initialValue={categoryDate?.category?.name}
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Room name" disabled />
                        </Form.Item>
                      </Col>

                      <Col>
                        <Form.Item
                          label="Number of person"
                          name={[index, "numberOfPerson"]}
                          rules={[{ required: true }]}
                        >
                          <Select>
                            {[...Array(categoryDate.category.maxPersonPerRoom)].map((element, index) => (
                              <Select.Option value={index + 1}>{index + 1}</Select.Option>
                            ))}

                          </Select>
                        </Form.Item>
                      </Col>

                      {rooms.length > 0 ? (
                        <Button
                          type="danger"
                          className="dynamic-delete-button"
                          onClick={() => remove(room.name)}
                          icon={<MinusCircleOutlined />}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </Row>

                  ))}
                  {roomAvailable.length > 0 && rooms.length < roomAvailable.length &&
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "60%" }}
                    >
                      <PlusOutlined /> Add room
                    </Button>}
                  {rooms.length > 0 && <Row style={{ justifyContent: 'right', marginRight: '10px' }}>
                    <Button onClick={next}>Next</Button>
                    
                  </Row>}

                </div>
              );
            }}
          </Form.List>

        </Form>



      </>
    )
  }

  const SecondStep = () => {


    const [phoneSearchLoading, setPhoneSearchLoading] = useState(false);

    

    const searchByPhoneNumber = (value) => {
      setPhoneSearchLoading(true);
      console.log(value);
      axios.get("https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/customers", { params: { phoneNo: value } }).then(res => {
        setPhoneSearchLoading(false);
        // setCategories(() => res.data.data);
      }, err => {
        setPhoneSearchLoading(false);
        console.error(err);
      })
    }

    const createUser = () => {
      console.log(customerForm.getFieldsValue());
      axios.post("https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/customers", customerForm.getFieldsValue()).then(res => {
        // setPhoneSearchLoading(false);
        console.log(res);
        customerForm.setFieldsValue(res.data.data);
        // setCategories(() => res.data.data);
      }, err => {
        console.error(err);
      })
    }

    return <>
      {!isAuthenticated && <><Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col><h2>Already Registered?</h2></Col>
      </Row>
      <Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col>
          <Input.Search
            loading={emailSearchLoading}
            type={'email'}
            placeholder='Search by email'
            onSearch={searchByEmail}
            enterButton="Search"
          />
        </Col>
      </Row><br></br>
      <Divider>OR</Divider></>}
      <Form form={customerForm} style={{ marginTop: '50px' }} name="basic">
        <Row style={{ justifyContent: 'center' }} gutter={16}>
          <Col><h2>Guest Information</h2></Col>
        </Row>
        <Row style={{ justifyContent: 'center' }} gutter={16}>
          {/* <Col> */}
          <Form.Item name="id">
            <Input placeholder='ID' hidden />
          </Form.Item>
          {/* </Col> */}
          <Col>
            <Form.Item name="name" label="Name">
              <Input placeholder='Name' />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="email" label="Email">
              <Input placeholder='Email' />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="phoneNo" label="Phone Number">
              <Input placeholder='Phone Number' />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }} gutter={16}>
          <Col><Button onClick={createUser}>{isAuthenticated ? 'Save' : 'Create / Update Guest'}</Button></Col>
        </Row>
        <Row style={{ justifyContent: 'right', marginRight: '10px' }} gutter={16}>
          <Col style={{justifyContent: 'space-around'}}>
          <Button onClick={prev}>Back</Button>
            <Button onClick={next}>Next</Button>
          </Col>
        </Row>

      </Form>
    </>;
  }


  const handlePayment = async (params) => {

    const data = await axios.post("https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/bookings", params);

    console.log(data);

    const options = {
      // key: "rzp_test_Ba1BL7UlUFiblT", // Enter the Key ID generated from the Dashboard
      key: "rzp_live_zjDCqJVLnOuS5X", // Enter the Key ID generated from the Dashboard
      amount: data.data.data.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: `StonedAge`,
      description: `${params.roomCount} room payment for ${(finalData.checkInOutDate[1].diff(finalData.checkInOutDate[0], 'days'))} days.`,
      image: "https://stonedage.in/images/favicon.png",
      order_id: data.data.data.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: async function (response) {
        const paymentData = { 
          id: data.data.data.data.id, 
          ...response, 
          advanceRecieved: data.data.data.order.amount / 100, 
          status: 'confirmed',
          refNo: response.razorpay_payment_id 
        };
        await axios.post("https://fsmvbeyu5i.execute-api.ap-south-1.amazonaws.com/api/bookings", paymentData);

        alert('Booking Confirmed!!!');
        // setFinalData({});
        // setStep(0);
        // setVisible(false);
        window.location.reload();
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: `${finalData.name}`,
        email: `${finalData.email}`,
        contact: `${finalData.phoneNo}`,
      },
      notes: {
        address: "Stonedage Payment",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);


    rzp1.open();
  }


  const Payment = () => {

    const [finalPaymentData, setFinalPaymentData] = useState();
    const [selectedCategory, setSelectedCategory] = useState();

    const [bookingData, setBookingData] = useState();

    useEffect(() => {

      console.log(finalData);

      setFinalPaymentData(data => ({ ...finalData }));
      setSelectedCategory(categories.find(item => item.id === finalData.categoryId));


      const selectedCategory = categories.find(item => item.id === finalData.categoryId);
      console.log( finalData.categoryId );
      console.log( finalData.checkInOutDate[0]._d );

      if (finalData.checkInOutDate[1].diff(finalData.checkInOutDate[0], 'days') === 0) {
        notification.error({
          message : 'Check in and Check out must not be same'
        })
        setStep(0);
      }


      API.post('backend-api', 'prices/check/ByDate', { body: {
        categoryId: finalData.categoryId,
        checkInDate: finalData.checkInOutDate[0],
        checkOutDate: finalData.checkInOutDate[1]
      }}).then(
      res => {
        console.log( res );
          setBookingData(() => ({
            numberOfPerson: finalData.rooms.map(item => item.numberOfPerson).reduce((a, b) => a + b, 0),
            checkInDate: finalData.checkInOutDate[0],
            checkOutDate: finalData.checkInOutDate[1],
            categoryId: finalData.categoryId,
            roomCount: finalData.rooms.length,
            customerId: finalData.id,
            room: finalData.rooms.map(item => item.id),
            status: 'pending',
            amountWithoutTax: (res.data.totalPrice * finalData.rooms.length).toFixed(2),
            tax: ((res.data.totalPrice * finalData.rooms.length) * 0.12).toFixed(2),
            total: ((res.data.totalPrice* finalData.rooms.length) * 1.12).toFixed(2)
          }));
      }, err => {
        console.error(err);
      }
    );
    
      // finalData.rooms.map(item => item.numberOfPerson).reduce((a, b) => a + b, 0);

      // setBookingData(() => ({
      //   numberOfPerson: finalData.rooms.map(item => item.numberOfPerson).reduce((a, b) => a + b, 0),
      //   checkInDate: finalData.checkInOutDate[0],
      //   checkOutDate: finalData.checkInOutDate[1],
      //   room: finalData.rooms.map(item => item.id),
      //   categoryId: finalData.categoryId,
      //   roomCount: finalData.rooms.length,
      //   customerId: finalData.id,
      //   status: 'pending',
      //   amountWithoutTax: (selectedCategory.roomRate * finalData.rooms.length * (finalData.checkInOutDate[1].diff(finalData.checkInOutDate[0], 'days'))).toFixed(2),
      //   tax: ((selectedCategory.roomRate * finalData.rooms.length) * (finalData.checkInOutDate[1].diff(finalData.checkInOutDate[0], 'days')) * 0.12).toFixed(2),
      //   total: ((selectedCategory.roomRate * finalData.rooms.length) * (finalData.checkInOutDate[1].diff(finalData.checkInOutDate[0], 'days')) * 1.12).toFixed(2)
      // }));

    }, [])

    useEffect(() => {
      console.log(bookingData);
    }, [bookingData])


    return <>
      <Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col span={4} style={{ fontWeight: 'bolder', textAlign: 'right' }}>Room Category - </Col>
        <Col span={4} style={{ textAlign: 'right' }}>{categories.find(item => item.id === finalData.categoryId).name}</Col>
      </Row>
      <Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col span={4} style={{ fontWeight: 'bolder', textAlign: 'right' }}>Check In Date - </Col>
        <Col span={4} style={{ textAlign: 'right' }}>{bookingData?.checkInDate.format('DD, MMM YYYY')}</Col>
      </Row>
      <Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col span={4} style={{ fontWeight: 'bolder', textAlign: 'right' }}>Check Out Date - </Col>
        <Col span={4} style={{ textAlign: 'right' }}>{bookingData?.checkOutDate.format('DD, MMM YYYY')}</Col>
      </Row>
      <Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col span={4} style={{ fontWeight: 'bolder', textAlign: 'right' }}>Room Count - </Col>
        <Col span={4} style={{ textAlign: 'right' }}>{bookingData?.roomCount}</Col>
      </Row>
      <Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col span={4} style={{ fontWeight: 'bolder', textAlign: 'right' }}>Number Of Person - </Col>
        <Col span={4} style={{ textAlign: 'right' }}>{bookingData?.numberOfPerson}</Col>
      </Row>
      <Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col span={4} style={{ fontWeight: 'bolder', textAlign: 'right' }}>Amount  - </Col>
        <Col span={4} style={{ textAlign: 'right' }}>{bookingData?.amountWithoutTax}</Col>
      </Row>
      <Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col span={4} style={{ fontWeight: 'bolder', textAlign: 'right' }}>Tax (12%) - </Col>
        <Col span={4} style={{ textAlign: 'right' }}>{bookingData?.tax}</Col>
      </Row>
      <Row style={{ justifyContent: 'center' }} gutter={16}>
        <Col span={4} style={{ fontWeight: 'bolder', textAlign: 'right' }}>Total - </Col>
        <Col span={4} style={{ textAlign: 'right' }}>{bookingData?.total}</Col>
      </Row>

      <Row style={{ justifyContent: 'center', marginTop: '10px' }}><Col><Button onClick={prev}>Back</Button><Button onClick={() => handlePayment(bookingData)}>Book Now</Button></Col></Row>
    </>
  }

  const [currentStep, setCurrentStep] = useState(0)


  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  }

  const steps = [
    {
      title: 'Select Room',
      content: <FirstStep />

    },
    {
      title: 'Select Cutomer',
      content: <SecondStep />,
    },
    {
      title: 'Payment',
      content: <Payment />,
    },
  ];

  const { Step } = Steps;




  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
      <Modal width={1000} bodyStyle={{ height: '72vh' }} title="Check Availability" visible={isVisible} onCancel={() => setVisible(false)} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
        <Steps current={step}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content" style={{ height: '-webkit-fill-available', overflowX: 'auto' }}>{steps[step].content}</div>
        {/* <div className="steps-action">
          {step < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {step === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Processing complete!')}>
              Done
            </Button>
          )}
          {step > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div> */}
      </Modal>
    </>
  );
};

export default DialogBox;

// const domContainer = document.querySelector('#dialog-box');
// ReactDOM.render((DialogBox), domContainer);