import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppContext } from '../common/ContextLib';

const PrivateRoute = ({component: Component, role,  ...rest}) => {
    const { isAuthenticated, group } = useAppContext();
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isAuthenticated && role.includes(group) ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};

export default PrivateRoute;
