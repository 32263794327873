import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import 'antd/dist/antd.css';
import DialogBox from './components/DialogBox';
import { withAuthenticator, Authenticator } from '@aws-amplify/ui-react';
// import LoginPage from './pages/Login';
import '@aws-amplify/ui-react/styles.css';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);



function App() {

    const [visible, setVisible] = useState(false);

    return (

        <>
            <div className="App">
            </div>
            <div>
                <header className="header-area fixed header-sticky">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-5 col-md-4 col-sm-4 col-xs-12">
                                <div className="logo">
                                    <a href="/"><img src="img/logo/logo3.png" alt="Oestin" style={{ width: '250px' }} /></a>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-8 col-sm-8 hidden-xs">
                                <div className="header-top fix">
                                    <div className="header-contact">
                                        <span className="text-theme">Contact:</span>
                                        <span>+91-9805812303 / +91-9711003348 / +91-8791184974</span>
                                    </div>
                                    <div className="header-links">
                                        <a href="https://www.facebook.com/stonedage.in/"><i className="zmdi zmdi-facebook" /></a>
                                        <a href="https://twitter.com/"><i className="zmdi zmdi-twitter" /></a>
                                        <a href="https://plus.google.com/"><i className="zmdi zmdi-google-plus" /></a>
                                        <a href="https://www.instagram.com/stonedage_tosh"><i className="zmdi zmdi-instagram" /></a>
                                        <a href="https://api.whatsapp.com/send?phone=919805812303"><i className="zmdi zmdi-whatsapp" /></a>

                                    </div>
                                </div>
                                {/* Mainmenu Start */}
                                <div className="main-menu hidden-xs">
                                    <nav>
                                        <ul>
                                            <li><a href="/">HOME</a></li>
                                            <li><a href="about.html">ABOUT US</a>
                                            </li><li><a href="room-list.html">ROOMS</a>
                                                <ul className="submenu">
                                                    <li><a href="deluxe-room.html">Luxury Cottage</a></li>
                                                    <li><a href="family-room.html">Deluxe Family Cottage</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="attraction.html">ATTRACTIONS</a></li>
                                            <li><a href="event.html">GALLERY</a></li>
                                            <li><a href="tariff.html">BOOK NOW</a></li>
                                            <li><a href="contact.html">CONTACT US</a></li>
                                            {/* <button style={{ marginLeft: 15, height: 30, width: '80' }} onClick={LoginPage}>LogIn</button> */}
                                            {/* <button style={{marginLeft:10, height:30, width:'80'}} }>SignUp</button> */}
                                            {/* <li><a href="room-grid.html">ROOMS</a
                          <ul class="submenu">
                              <li><a href="room-list.html">ROOM LIST</a></li>
                              <li><a href="room-details.html">ROOM DETAILS</a></li>
                          </ul>
                      </li> */}
                                            {/* <li><a href="#">ACCOMMODATION</a>
                          <ul class="submenu megamenu">
                              <li><a href="#">Megamenu List</a>
                                  <ul>
                                      <li><a href="location.html">Location</a></li>
                                      <li><a href="room-grid.html">Room Grid</a></li>
                                      <li><a href="room-list.html">Room List</a></li>
                                      <li><a href="room-details.html">Room Details</a></li>
                                      <li><a href="#">Mega menu</a></li>
                                  </ul>
                              </li>
                              <li><a href="#">Megamenu List</a>
                                  <ul>
                                      <li><a href="event.html">Event</a></li>
                                      <li><a href="#">Mega menu</a></li>
                                      <li><a href="contact.html">Contact</a></li>
                                      <li><a href="team.html">Team</a></li>
                                      <li><a href="#">Mega menu</a></li>
                                  </ul>
                              </li>
                              <li><a href="#">Megamenu List</a>
                                  <ul>
                                      <li><a href="room-list.html">Room List</a></li>
                                      <li><a href="#">Mega menu</a></li>
                                      <li><a href="room-grid.html">Room Grid</a></li>
                                      <li><a href="room-details.html">Room Details</a></li>
                                      <li><a href="#">Mega menu</a></li>
                                  </ul>
                              </li>
                          </ul>
                      </li> 
                      <li><a href="location.html">LOCATION</a></li>
                      <li><a href="event.html">EVENT</a></li>
                      <li><a href="team.html">TEAM</a></li>
                      <li><a href="contact.html">CONTACT US</a></li>*/}
                                        </ul>
                                    </nav>
                                </div>
                                {/* Mainmenu End */}
                            </div>
                        </div>
                    </div>
                    {/* Mobile Menu Area start */}
                    <div className="mobile-menu-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="mobile-menu">
                                        <nav id="dropdown">
                                            <ul>
                                                <li><a href="/">HOME</a></li>
                                                <li><a href="room-grid.html">ROOMS</a>
                                                    <ul className="submenu">
                                                        <li><a href="room-list.html">ROOM LIST</a></li>
                                                        <li><a href="room-details.html">ROOM DETAILS</a></li>
                                                    </ul>
                                                </li>
                                                <li><a href="location.html">LOCATION</a></li>
                                                <li><a href="event.html">EVENT</a></li>
                                                <li><a href="team.html">TEAM</a></li>
                                                <li><a href="contact.html">CONTACT</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Mobile Menu Area end */}
                </header>
                {/* Header Area End */}
                {/* Background Area Start */}
                <section className="slider-area">
                    <div className="slider-wrapper">
                        <div className="single-slide" style={{ backgroundImage: 'url("img/slider/6.jpg")' }}>
                            <div className="banner-content overlay">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-10 col-md-offset-1">
                                            <div className="text-content-wrapper">
                                                <div className="text-content text-center">
                                                    <h1 className="pt-180">Welcome to</h1>
                                                    <h1 style={{ color: 'white' }}>StonedAge Café &amp; INN</h1>
                                                    <div className="banner-btn">
                                                        <a className="default-btn" href="room-list.html">explore</a>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-slide" style={{ backgroundImage: 'url("img/slider/7.jpg")' }}>
                            <div className="banner-content overlay">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-10 col-md-offset-1">
                                            <div className="text-content-wrapper slide-two">
                                                <div className="text-content text-center">
                                                    <h1 className="pt-180">Every morning</h1>
                                                    <h2 style={{ color: 'white' }}>We are born again in the midst of Himalayas.</h2>
                                                    <div className="banner-btn">
                                                        <a className="default-btn" href="room-list.html" style={{ marginTop: '20px' }}>explore</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Background Area End */}
                {/* About Us Area Start */}
                <section className="about-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <form className="search-form">
                                    <div className="form-container fix">
                                        <div className="box-select">
                                            <div className="select date">
                                                <input type="date" name="arrive" />
                                            </div>
                                            <div className="select date">
                                                <input type="date" name="departure" />
                                            </div>
                                            <div className="select arrow">
                                                <select name="adults">
                                                    <option>ADULTS</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                </select>
                                            </div>
                                            <div className="select arrow">
                                                <select name="children">
                                                    <option>CHILDREN</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button type="button" className="default-btn" onClick={() => setVisible(true)} >Check Availability</button>
                                        <DialogBox isVisible={visible} setVisible={() => setVisible(false)}></DialogBox>
                                    </div>

                                </form>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <div className="video-wrapper mt-90">
                                    <div className="video-overlay">
                                        <img src="img/banner/5.jpg" alt="" />
                                    </div>
                                    <a className="video-popup" href="https://www.youtube.com/watch?v=7WIeKCK-nys">
                                        <i className="zmdi zmdi-play-circle-outline" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="about-text">
                                    <div className="section-title">
                                        <h2>ABOUT US</h2>
                                        <p><br />StonedAge is not just a venture for us, it is a <strong>budding dream very close to our heart!</strong> We are old school and so is StonedAge. We realized that the only way to achieve our dream is to work towards it, the first hand research is what yields the best results.</p>
                                        <p>So we, founders of this heaven in the midst of himalayas, packed our bags in yr-2017 and embarked upon this exciting journey to gain some experience of having a <strong>BEST PLACE TO LIVE IN THE MIDDLE OF NOWHERE.</strong></p>
                                        <p>Exotic 5 cottages and 1 restaurant at Tosh Village, Parvati Valley 2500 mtrs above sea level, non motarable hike trail,  <strong>covered with apple orchards</strong> which will take you away.</p>
                                    </div>
                                    <div className="about-links">
                                        <a href="https://www.facebook.com/stonedage.in/"><i className="zmdi zmdi-facebook" /></a>
                                        <a href="https://www.instagram.com/stonedage_tosh"><i className="zmdi zmdi-instagram" /></a>
                                        <a href="https://www.rss.com/"><i className="zmdi zmdi-rss" /></a>
                                        <a href="https://twitter.com/"><i className="zmdi zmdi-twitter" /></a>
                                        <a href="https://api.whatsapp.com/send?phone=919805812303"><i className="zmdi zmdi-whatsapp" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* About Us Area End */}
                {/* Room Area Start */}
                <section className="room-area pt-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="section-title text-center">
                                    <h2>Property</h2>
                                    <h5><br />5 Cottages and one inhouse restraunt to give you the peaceful night and healthy cusines round the clock.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="single-room small">
                            <img src="img/room/0001.jpg" alt="" />
                            <div className="room-hover text-center">
                                <div className="hover-text">
                                    <h3><a href="room-details.html">Luxury Cottages</a></h3>
                                    <p>Premium cottages add a rustic experience along with extreme comfort. Four people can easily stay together . Attached washrooms , almira , ample luggage space.</p>
                                    <div className="room-btn">
                                        <a href="room-details.html" className="default-btn">DETAILS</a>
                                    </div>
                                </div>
                                <div className="p-amount">
                                    <span>₹5000+</span>
                                    <span className="count">Per Night</span>
                                </div>
                            </div>
                        </div>
                        <div className="single-room small">
                            <img src="img/room/0003.jpg" alt="" />
                            <div className="room-hover text-center">
                                <div className="hover-text">
                                    <h3><a href="family-room.html">Inhouse Restaurant</a></h3>
                                    <p>Enjoy mouth watering cusines from our inhouse restaurant.</p>
                                    <div className="room-btn">
                                        <a href="family-room.html" className="default-btn">DETAILS</a>
                                    </div>
                                </div>
                                <div className="p-amount">
                                    <span>Alacarte</span>
                                    <span className="count">MAP</span>
                                </div>
                            </div>
                        </div>
                        <div className="single-room small">
                            <img src="img/room/0002.jpg" alt="" />
                            <div className="room-hover text-center">
                                <div className="hover-text">
                                    <h3><a href="room-details.html">Deluxe Family Cottages</a></h3>
                                    <p>Group Stay Cottages along with a rustic experience, bring a feeling of togetherness 6-7 people can stay in one space. Attached washrooms, Elmira, ample luggage space.</p>
                                    <div className="room-btn">
                                        <a href="family.html" className="default-btn">DETAILS</a>
                                    </div>
                                </div>
                                <div className="p-amount">
                                    <span>₹6500+</span>
                                    <span className="count">Per Night</span>
                                </div>
                            </div>
                            {/*
  <div class="single-room small">
      <img src="img/room/3.jpg" alt="">
      <div class="room-hover text-center">
          <div class="hover-text">
              <h3><a href="room-details.html">Single Room</a></h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
              <div class="room-btn">
                  <a href="room-details.html" class="default-btn">DETAILS</a>
              </div>
          </div>
          <div class="p-amount">
              <span>$120</span>
              <span class="count">Per Night</span>
          </div>
      </div>
  </div>
  <div class="single-room medium">
      <img src="img/room/4.jpg" alt="">
      <div class="room-hover text-center">
          <div class="hover-text">
              <h3><a href="room-details.html">Double Room</a></h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
              <div class="room-btn">
                  <a href="room-details.html" class="default-btn">DETAILS</a>
              </div>
          </div>
          <div class="p-amount">
              <span>$100</span>
              <span class="count">Per Night</span>
          </div>
      </div>
  </div> */}
                        </div>
                    </div></section>
                {/* Room Area End */}
                {/* Services Area Start */}
                <section className="services-area ptb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title text-center">
                                    <h2>OUR SERVICES</h2>
                                    <h5><br />Our energy and Passion: to build such a place is a miracle and madness. This place is a great example of human</h5>
                                    <h5><br />efforts getting blended with Architectural amuses.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                {/* Nav tabs */}
                                <ul role="tablist" className="nav nav-tabs">
                                    <li className="active" role="presentation">
                                        <a data-toggle="tab" role="tab" aria-controls="spa" href="#spa" aria-expanded="true">
                                            <span className="image p-img"><img src="img/icon/spa.png" alt="" /></span>
                                            <span className="image s-img"><img src="img/icon/spa-hover.png" alt="" /></span>
                                            <span className="title">BONFIRE</span>
                                            <span className="text">The flames once again witnessed the memories of your life at home aways from home.</span>
                                        </a>
                                    </li>
                                    <li role="presentation">
                                        <a data-toggle="tab" role="tab" aria-controls="swim" href="#swim" aria-expanded="true">
                                            <span className="image p-img"><img src="img/icon/swim.png" alt="" /></span>
                                            <span className="image s-img"><img src="img/icon/swim-hover.png" alt="" /></span>
                                            <span className="title">HIKES (of everykind)</span>
                                            <span className="text">If thinking about it makes you excited, imagine actually doing it!</span>
                                        </a>
                                    </li>
                                    <li role="presentation">
                                        <a data-toggle="tab" role="tab" aria-controls="restaurant" href="#restaurant" aria-expanded="true">
                                            <span className="image p-img"><img src="img/icon/restaurent.png" alt="" /></span>
                                            <span className="image s-img"><img src="img/icon/restaurent-hover.png" alt="" /></span>
                                            <span className="title">DELICACIES</span>
                                            <span className="text">Where taste meets the myth. Eat healthy and live better</span>
                                        </a>
                                    </li>
                                    <li role="presentation">
                                        <a data-toggle="tab" role="tab" aria-controls="conference" href="#conference" aria-expanded="true">
                                            <span className="image p-img"><img src="img/icon/conference.png" alt="" /></span>
                                            <span className="image s-img"><img src="img/icon/conference-hover.png" alt="" /></span>
                                            <span className="title">APPLE PICKING</span>
                                            <span className="text">Love that moment of the year when we celebrate this featival in parvati valley. Join us for visiting the best time of the year and take home a full basket memories of LOVE &amp; HAPPINESS.</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-7">
                                <div className="tab-content">
                                    <div id="spa" className="tab-pane active" role="tabpanel">
                                        <img src="img/banner/1.jpg" alt="" />
                                    </div>
                                    <div id="swim" className="tab-pane" role="tabpanel">
                                        <img src="img/banner/2.jpg" alt="" />
                                    </div>
                                    <div id="restaurant" className="tab-pane" role="tabpanel">
                                        <img src="img/banner/3.jpg" alt="" />
                                    </div>
                                    <div id="conference" className="tab-pane" role="tabpanel">
                                        <img src="img/banner/4.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="container">
      <div class="row">
          <div class="col-lg-12 ">
              <div class="section-title text-left" style="padding: 0 0;">
                  <h3></h3>
                  <p>Along with our specialities, we have the competent manpower to offer the best services to you at 2500 mtrs altitude. Our team has travelled more than 70,000 kms within India and experienced sleeping under starry skies of Rajasthan; of getting lost hiking alongside the coastline of Gokarna (Karnataka), of exploring living-root bridges in Meghalaya, Roaming around Himalayas. We met people and made friends out of complete strangers. We tasted food we never knew existed. We understood cultures and the differences. Above all, we realized and experienced the innate need to “belong”.</p>
              </div>
          </div>
      </div>
  </div> */}
                        {/* <div class="container">
      <div class="row">
          <div class="col-md-6">
              <div class="List">
                   <ul style="font-size: 15px;">
                       <li>Restaurant</li>
                       <li>Famous Treks of Parvati valley</li>
                       <li>Night Expedition & Safari</li>
                       <li>Apple Picking Festival</li>
                       <li>Musical nights (Himachali Folk and Western)</li>
                   </ul>
              </div>
          </div>
          <div class="col-md-6">
              <div class="List">
                   <ul style="font-size: 15px;">
                       <li>Outdoor and Indoor Games</li>
                       <li>Adventure sports (coming soon)</li>
                       <li>Expedition Events and Parties</li>
                       <li>Local Himachali Cuisines and Barbeque</li>
                       <li>Bonfire</li>
                   </ul>
              </div>
          </div>
      </div>
  </div> */}
                    </div>
                </section>
                {/* Services Area End */}
                {/* one more about section */}
                {/* <section class="services-area" style="padding-bottom: 5rem;">
    <div class="container">
  <div class="row">
      <div class="col-md-7">
          <div class="video-wrapper mt-90">
              <div class="video-overlay">
                  <img src="img/banner/4.jpg" alt="">
              </div>
              <a class="video-popup" href="https://www.youtube.com/watch?v=rXcp6s0VjZk">
                  <i class="zmdi zmdi-play-circle-outline"></i>
              </a>
          </div>
      </div>
      <div class="col-md-5">
          <div class="about-text">
              <div class="section-title">
                  <h3>about us</h3>
                  <p>StonedAge is not just a venture for us, it is a budding dream very close to our heart! We are old school and so is StonedAge. We realized that the only way to achieve our dream is to work towards it, the first hand research is what yields the best results. </p>
                  <p>so we packed our bags and embarked upon this exciting journey to gain some experience of having a BEST PLACE TO LIVE IN THE MIDDLE OF NOWHERE – The Great Himalayas.</p>
                  
              </div>
              <div class="about-links">
                  <a href="https://www.facebook.com/"><i class="zmdi zmdi-facebook"></i></a>
                  <a href="https://www.instagram.com/"><i class="zmdi zmdi-instagram"></i></a>
                  <a href="https://www.rss.com/"><i class="zmdi zmdi-rss"></i></a>
                  <a href="https://twitter.com/"><i class="zmdi zmdi-twitter"></i></a>
                  <a href="https://www.pinterest.com/"><i class="zmdi zmdi-pinterest"></i></a>
              </div>
          </div>
      </div>
  </div>
    </div>
    
</section> */}
                {/* end one more about section */}
                {/* Fun Factor Area Start */}
                <section className="fun-factor-area bg-1 overlay-dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-3">
                                <div className="single-fun-factor text-center">
                                    <h1><span className="counter">5</span></h1>
                                    <h4>Years of operation</h4>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="single-fun-factor text-center">
                                    <h1><span className="counter">12</span></h1>
                                    <h4>Our Team</h4>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="single-fun-factor text-center">
                                    <h1><span className="counter">500</span></h1>
                                    <h4>Testimonies</h4>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="single-fun-factor text-center">
                                    <h1><span className="counter">4500</span></h1>
                                    <h4>Happy guests</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Fun Factor Area End */}
                {/* Gallery Area Start */}
                <section className="gallery-area pt-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="section-title text-center">
                                    <h2>GALLERY</h2>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellente sque vel volutpat felis, eu condimentum massa. Pellentesque mollis eros vel mattis tempor. Aliquam </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-container">
                        <div className="gallery-filter">
                            <button data-filter="*" className="active"> <strong>All</strong></button>
                            <button data-filter=".outdoor"><strong>PROPERTY</strong></button>
                            <button data-filter=".food"><strong>TASTE BUDS</strong></button>
                            {/* <button data-filter=".hike"><strong>TREKS</strong></button> */}
                            <button data-filter=".events"><strong>ADVENTURE</strong></button>
                            <button data-filter=".mrch"><strong>MERCHANDISE</strong></button>
                        </div>
                        <div className="gallery gallery-masonry">
                            <div className="gallery-item outdoor events">
                                <div className="thumb">
                                    <img src="img/gallery/28.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor">
                                <div className="thumb">
                                    <img src="img/gallery/21.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor">
                                <div className="thumb">
                                    <img src="img/gallery/23.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor events">
                                <div className="thumb">
                                    <img src="img/gallery/24.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor">
                                <div className="thumb">
                                    <img src="img/gallery/26.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor">
                                <div className="thumb">
                                    <img src="img/gallery/25.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor events">
                                <div className="thumb">
                                    <img src="img/gallery/27.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor events">
                                <div className="thumb">
                                    <img src="img/gallery/29.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor">
                                <div className="thumb">
                                    <img src="img/gallery/221.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor">
                                <div className="thumb">
                                    <img src="img/gallery/22.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor">
                                <div className="thumb">
                                    <img src="img/gallery/222.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item outdoor events">
                                <div className="thumb">
                                    <img src="img/gallery/32.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item events">
                                <div className="thumb">
                                    <img src="img/gallery/31.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item events">
                                <div className="thumb">
                                    <img src="img/gallery/30.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item">
                                <div className="thumb">
                                    <img src="img/gallery/33.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item">
                                <div className="thumb">
                                    <img src="img/gallery/34.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item">
                                <div className="thumb">
                                    <img src="img/gallery/35.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/1.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item mrch">
                                <div className="thumb">
                                    <img src="img/gallery/40.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/2.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item mrch">
                                <div className="thumb">
                                    <img src="img/gallery/45.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/3.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item mrch">
                                <div className="thumb">
                                    <img src="img/gallery/42.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/4.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item mrch">
                                <div className="thumb">
                                    <img src="img/gallery/44.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/5.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item mrch">
                                <div className="thumb">
                                    <img src="img/gallery/46.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/5.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item mrch">
                                <div className="thumb">
                                    <img src="img/gallery/47.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/5.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item food">
                                <div className="thumb">
                                    <img src="img/gallery/50.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/6.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item food">
                                <div className="thumb">
                                    <img src="img/gallery/51.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/6.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item food">
                                <div className="thumb">
                                    <img src="img/gallery/52.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/6.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item food">
                                <div className="thumb">
                                    <img src="img/gallery/53.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/6.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="gallery-item food">
                                <div className="thumb">
                                    <img src="img/gallery/54.jpg" alt="" />
                                </div>
                                <div className="gallery-hover">
                                    <div className="gallery-icon">
                                        <a href="#">
                                            <span className="p-img"><img src="img/icon/link.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/link-hover.png" alt="" /></span>
                                        </a>
                                        <a className="image-popup" href="img/gallery/6.jpg">
                                            <span className="p-img"><img src="img/icon/search.png" alt="" /></span>
                                            <span className="s-img"><img src="img/icon/search-hover.png" alt="" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Gallery Area End */}
                {/* Team Area Start */}
                {/* <section class="team-area ptb-90">
    <div class="container">
  <div class="row">
      <div class="col-md-8 col-md-offset-2">
          <div class="section-title text-center">
              <h3>our special staff</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellente sque vel volutpat felis, eu condimentum massa. Pellentesque mollis eros vel mattis tempor. Aliquam </p>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-md-4 col-sm-4">
          <div class="single-team">
              <div class="team-image"><img src="img/team/1.jpg" alt=""></div>
              <div class="team-hover">
                  <h4>Kathy Luis</h4>
                  <span class="block">( Officer )</span>
                  <p>Lorem ipsupm dolor sit amet, conse ctetur adipisicing elit, sed do eiumthgtipsupm dolor sit amet conse</p>
                  <div class="team-links">
                      <a href="https://www.facebook.com/"><i class="zmdi zmdi-facebook"></i></a>
                      <a href="https://twitter.com/"><i class="zmdi zmdi-twitter"></i></a>
                      <a href="https://www.pinterest.com/"><i class="zmdi zmdi-pinterest"></i></a>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-md-4 col-sm-4">
          <div class="single-team">
              <div class="team-image"><img src="img/team/2.jpg" alt=""></div>
              <div class="team-hover">
                  <h4>Them Jonse</h4>
                  <span class="block">( Manager )</span>
                  <p>Lorem ipsupm dolor sit amet, conse ctetur adipisicing elit, sed do eiumthgtipsupm dolor sit amet conse</p>
                  <div class="team-links">
                      <a href="https://www.facebook.com/"><i class="zmdi zmdi-facebook"></i></a>
                      <a href="https://twitter.com/"><i class="zmdi zmdi-twitter"></i></a>
                      <a href="https://www.pinterest.com/"><i class="zmdi zmdi-pinterest"></i></a>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-md-4 col-sm-4">
          <div class="single-team">
              <div class="team-image"><img src="img/team/3.jpg" alt=""></div>
              <div class="team-hover">
                  <h4>Marry Gomej</h4>
                  <span class="block">( Leader )</span>
                  <p>Lorem ipsupm dolor sit amet, conse ctetur adipisicing elit, sed do eiumthgtipsupm dolor sit amet conse</p>
                  <div class="team-links">
                      <a href="https://www.facebook.com/"><i class="zmdi zmdi-facebook"></i></a>
                      <a href="https://twitter.com/"><i class="zmdi zmdi-twitter"></i></a>
                      <a href="https://www.pinterest.com/"><i class="zmdi zmdi-pinterest"></i></a>
                  </div>
              </div>
          </div>
      </div>
  </div>
    </div>
</section> */}
                {/* Team Area End */}
                {/* Advertise Area Start */}
                {/* <section class="advertise-area bg-2 overlay-dark" style="margin-top: 8rem;">
    <div class="container">
  <div class="row">
      <div class="col-md-12">
          <div class="advertise-text">
              <h1>get <span>15% off</span> on any other events... </h1>
              <a href="#" class="default-btn">Book Now</a>
          </div>
      </div>
  </div>
    </div>
</section> */}
                {/* Advertise Area End */}
                {/* Pricing Area Start */}
                {/* <section class="pricing-area ptb-90">
    <div class="container">
  <div class="row">
      <div class="col-md-8 col-md-offset-2">
          <div class="section-title text-center">
              <h3>Our Pricing</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellente sque vel volutpat felis, eu condimentum massa. Pellentesque mollis eros vel mattis tempor. Aliquam </p>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-md-4 col-sm-4">
          <div class="single-pricing">
              <div class="package-name">
                  <h3>Silver Pack</h3>
                  <h1><span class="currency">$</span>150 <span class="count">/per night</span></h1>
              </div>
              <div class="package-offer">
                  <span>Flight Ticket</span>
                  <span>Music Concert (30% Off)</span>
                  <span>Restaurant (Lunch)</span>
                  <span class="light">Treatment</span>
                  <span class="light">Face Make</span>
              </div>
              <div class="signup-btn">
                  <a href="#" class="default-btn">SIGN UP</a>
              </div>
          </div>
      </div>
      <div class="col-md-4 col-sm-4">
          <div class="single-pricing best-offer">
              <div class="package-name">
                  <h3>Gold Pack</h3>
                  <h1><span class="currency">$</span>140 <span class="count">/per night</span></h1>
              </div>
              <div class="package-offer">
                  <span>Flight Ticket</span>
                  <span>Music Concert (30% Off)</span>
                  <span>Restaurant (Lunch)</span>
                  <span>Treatment</span>
                  <span class="light">Face Make</span>
              </div>
              <div class="signup-btn">
                  <a href="#" class="default-btn">SIGN UP</a>
              </div>
          </div>
      </div>
      <div class="col-md-4 col-sm-4">
          <div class="single-pricing">
              <div class="package-name">
                  <h3>Diamond Pack</h3>
                  <h1><span class="currency">$</span>190 <span class="count">/per night</span></h1>
              </div>
              <div class="package-offer">
                  <span>Flight Ticket</span>
                  <span>Music Concert (30% Off)</span>
                  <span>Restaurant (Lunch)</span>
                  <span>Treatment</span>
                  <span>Face Make</span>
              </div>
              <div class="signup-btn">
                  <a href="#" class="default-btn">SIGN UP</a>
              </div>
          </div>
      </div>
  </div>
    </div>
</section> */}
                {/* Pricing Area End */}
                {/* Testimonial start */}
                {/* TESTIMONIALS */}
                <section className="testimonials">
                    <div className="container" style={{ marginTop: '8rem' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h2>TESTIMONIAL</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div id="customers-testimonials" className="owl-carousel">
                                    {/*TESTIMONIAL 1 */}
                                    <div className="item">
                                        <div className="shadow-effect">
                                            <img className="img-circle" src="img/icon/1.jpg" alt="" />
                                            <p>Stoned age is the most beautiful and peaceful property in Tosh. The view from this place is magnificent. The cafe is my favourite place in this property and the view is breathtaking. I don’t remember how many times I used the word WOW during my stay.</p>
                                        </div>
                                        <div className="testimonial-name">Vidya - Trip Advisor</div>
                                    </div>
                                    {/*END OF TESTIMONIAL 1 */}
                                    {/*TESTIMONIAL 2 */}
                                    <div className="item">
                                        <div className="shadow-effect">
                                            <img className="img-circle" src="img/icon/2.jpg" alt="" />
                                            <p>Recently me my friend and cousin were on himachal trip and we had a two day stay in stonedage and i must say this place has something really magical , everything was soo amazing about this place , there food was delicious , there hospitality was soo good infact the cottages were so cozy so attractive and..</p>
                                        </div>
                                        <div className="testimonial-name">PASEAR CON HARSH</div>
                                    </div>
                                    {/*END OF TESTIMONIAL 2 */}
                                    {/*TESTIMONIAL 3 */}
                                    <div className="item">
                                        <div className="shadow-effect">
                                            <img className="img-circle" src="img/icon/3.jpg" alt="" />
                                            <p>My husband planned this surprise trip for our 1st anniversary and i had no clue where were we headed. I was so tired walking uphill till the cafe that i already started regretting the decision to come here But then.... When i reached the place, the view, the peace, the serenity took my breath away. My all lethargy vanished and i knew my husband made a good choice !.</p>
                                        </div>
                                        <div className="testimonial-name">BHAKTI</div>
                                    </div>
                                    {/*END OF TESTIMONIAL 3 */}
                                    {/*TESTIMONIAL 4 */}
                                    <div className="item">
                                        <div className="shadow-effect">
                                            <img className="img-circle" src="img/icon/4.jpg" alt="" />
                                            <p>Can’t get enough of this place!! - After an epic vacation at Stoned Age, Tosh in July 2018, we went there again in Dec 2020, this time to experience the chilling cold along with the warm hospitality that’s always a given at Stoned Age. Get your dose of adventure on the 30-45 min hike up to the property from Tosh village and the multiple hikes and trails.</p>
                                        </div>
                                        <div className="testimonial-name">ROUSHNI</div>
                                    </div>
                                    {/*END OF TESTIMONIAL 4 */}
                                    {/*TESTIMONIAL 5 */}
                                    <div className="item">
                                        <div className="shadow-effect">
                                            <img className="img-circle" src="img/icon/5.jpg" alt="" />
                                            <p>One of the most amazing places to spend your vacation. Our experience 2 years back in summers was so mesmerizing, that we made it a point to visit this place again in winters. Place is perfectly placed to enjoy an uninterrupted view. Their cozy cafe is one of the coolest places where you could spend hours at stretch.</p>
                                        </div>
                                        <div className="testimonial-name">HIMANSHU N</div>
                                    </div>
                                    {/*END OF TESTIMONIAL 5 */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END OF TESTIMONIALS */}
                {/* Blog Area Start */}
                {/* <section className="blog-area" style={{ marginTop: '8rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="section-title text-center">
                                    <h2>PUBLIC BLOGS</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="blog-carousel">
                                <div className="col-xs-12">
                                    <div className="single-blog-wrapper">
                                        <div className="single-blog">
                                            <div className="blog-image">
                                                <img src="img/blog/1.jpg" alt="" />
                                            </div>
                                            <div className="blog-text">
                                                <span className="time"><i className="zmdi zmdi-time" />Jun 25, 2019</span>
                                                <h3>Continuing the Adventure..</h3>
                                            </div>
                                        </div>
                                        <div className="blog-hover">
                                            <span><i className="zmdi zmdi-time" />Jun 25, 2019</span>
                                            <h3><a href="https://www.tripoto.com/kasol/trips/continuing-the-adventure-day-3-5c740c833af64" target="_blank">Tripoto - Continuing the Adventure - kasol and Tosh</a></h3>
                                            <p>I am sure, a lot of you woukd have by now read about our 'ExtraOddNary' Experience on our First Day in Kasol. If you havent yet, you should 🙈 </p>
                                            <div className="post-info">
                                                <span><a href="#"><i className="zmdi zmdi-account" />Just A Pack Of TWO</a></span>
                                            </div>
                                            <a href="#" className="default-btn">Read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="single-blog-wrapper">
                                        <div className="single-blog">
                                            <div className="blog-image">
                                                <img src="img/blog/2.jpg" alt="" />
                                            </div>
                                            <div className="blog-text">
                                                <span className="time"><i className="zmdi zmdi-time" />Nov 23, 2017</span>
                                                <h3>A trip to TOSH..</h3>
                                            </div>
                                        </div>
                                        <div className="blog-hover">
                                            <span><i className="zmdi zmdi-time" />Nov 23, 2017</span>
                                            <h3><a href="https://www.tripoto.com/himachal-pradesh/trips/a-trip-to-tosh-experience-tranquility-solitude-in-between-the-great-himalayas-5a1fe95c92873" target="_blank">A trip to TOSH - Experience Tranquility &amp; Solitude in between the Great Himalayas</a></h3>
                                            <p>Crawl up the mountains, pass many a turns, cross the river and ridges and keep moving up the meandering road that leads you to this land of god, believers and picturesque views - Tosh. </p>
                                            <div className="post-info">
                                                <span><a href="#"><i className="zmdi zmdi-account" />By Prateek</a></span>
                                                <span><a href="#"><i className="zmdi zmdi-favorite" />7.5K Views</a></span>
                                            </div>
                                            <a href="#" className="default-btn">Read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="single-blog-wrapper">
                                        <div className="single-blog">
                                            <div className="blog-image">
                                                <img src="img/blog/3.jpg" alt="" />
                                            </div>
                                            <div className="blog-text">
                                                <span className="time"><i className="zmdi zmdi-time" />Oct 01, 2017</span>
                                                <h3>Trip advisor, official..</h3>
                                            </div>
                                        </div>
                                        <div className="blog-hover">
                                            <span><i className="zmdi zmdi-time" />Oct 01, 2017</span>
                                            <h3><a href="https://www.tripadvisor.ca/Hotel_Review-g12823832-d13528651-Reviews-Stonedage_Cafe_Inn-Tosh_Kullu_District_Himachal_Pradesh.html" target="_blank">Trip advisor, official..</a></h3>
                                            <p>StonedAge is a united effort by a group of adventurers who want to share their journey with you and want you to have an unforgettable experience.</p>
                                            <div className="post-info">
                                                <span><a href="#"><i className="zmdi zmdi-account" />By Operations team</a></span>
                                                <span><a href="#"><i className="zmdi zmdi-favorite" />1500+ Likes Insta</a></span>
                                                <span><a href="#"><i className="zmdi zmdi-comments" />55 Comments</a></span>
                                            </div>
                                            <a href="#" className="default-btn">Read more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* Blog Area End */}
                {/* Client Area Start */}{/* 
<div class="client-area">
<div class="container">
    <div class="row">
  <div class="client-carousel">
      <div class="single-client">
          <div class="client-image">
              <a href="#">
                  <img src="img/client/1.png" alt="" class="p-img">
                  <img src="img/client/1-hover.png" alt="" class="s-img">
              </a>
          </div>
      </div>
      <div class="single-client">
          <div class="client-image">
              <a href="#">
                  <img src="img/client/2.png" alt="" class="p-img">
                  <img src="img/client/2-hover.png" alt="" class="s-img">
              </a>
          </div>
      </div>
      <div class="single-client">
          <div class="client-image">
              <a href="#">
                  <img src="img/client/3.png" alt="" class="p-img">
                  <img src="img/client/3-hover.png" alt="" class="s-img">
              </a>
          </div>
      </div>
      <div class="single-client">
          <div class="client-image">
              <a href="#">
                  <img src="img/client/4.png" alt="" class="p-img">
                  <img src="img/client/4-hover.png" alt="" class="s-img">
              </a>
          </div>
      </div>
      <div class="single-client">
          <div class="client-image">
              <a href="#">
                  <img src="img/client/5.png" alt="" class="p-img">
                  <img src="img/client/5-hover.png" alt="" class="s-img">
              </a>
          </div>
      </div>
  </div>
    </div>
</div>
</div> */}
                {/* Client Area End */}
                {/* Newsletter Area Start */}
                <section className="newsletter-area bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2 col-sm-12 col-xs-12">
                                <div className="newsletter-container">
                                    <h3>NewsLetter Sign-Up</h3>
                                    <div className="newsletter-form">
                                        <form action="#" id="mc-form" className="mc-form fix">
                                            <input id="mc-email" type="email" name="email" placeholder="Enter your E-mail" />
                                            <button id="mc-submit" type="submit" className="default-btn">subcribe</button>
                                        </form>
                                        {/* mailchimp-alerts Start */}
                                        <div className="mailchimp-alerts">
                                            <div className="mailchimp-submitting" />{/* mailchimp-submitting end */}
                                            <div className="mailchimp-success" />{/* mailchimp-success end */}
                                            <div className="mailchimp-error" />{/* mailchimp-error end */}
                                        </div>
                                        {/* mailchimp-alerts end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Newsletter Area End */}
                {/* Footer Area Start */}
                <footer className="footer-area">
                    {/* Footer Widget Start */}
                    <div className="footer-widget-area bg-dark">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                                    <div className="single-footer-widget">
                                        <div className="footer-logo">
                                            <a href="/"><img src="img/logo/3.png" alt="Oestin" style={{ width: '100%' }} /></a>
                                        </div>
                                        <p>StonedAge is not just a venture for us, it is a budding dream very close to our heart!  We are old school and so is StonedAge. We realized that the only way to achieve our dream is to work towards it.</p>
                                        <div className="social-icons">
                                            <a href="https://www.facebook.com/stonedage.in/"><i className="zmdi zmdi-facebook" /></a>
                                            <a href="https://www.instagram.com/stonedage_tosh"><i className="zmdi zmdi-instagram" /></a>
                                            <a href="https://www.rss.com/"><i className="zmdi zmdi-rss" /></a>
                                            <a href="https://twitter.com/"><i className="zmdi zmdi-twitter" /></a>
                                            <a href="https://api.whatsapp.com/send?phone=919805812303"><i className="zmdi zmdi-whatsapp" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                                    <div className="single-footer-widget">
                                        <h3>contact us</h3>
                                        <div className="c-info">
                                            <span><i className="zmdi zmdi-pin" /></span>
                                            <span>Stonedage Cafe and Inn - TOSH, Near TOSH waterfall - Kuchumb, Village TOSH Gram Barshini Dist Kullu, Himachal Pradesh- 175105</span>
                                        </div>
                                        <div className="c-info">
                                            <span><i className="zmdi zmdi-email" /></span>
                                            <span>stonedage.in@gmail.com<br />www.stonedage.in</span>
                                        </div>
                                        <div className="c-info">
                                            <span><i className="zmdi zmdi-phone" /></span>
                                            <span>+91-98058 12303 / +91-87911 84974 / +91-97110 03348 / +91-95825 50799 / +91-98058 25205  <br /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 hidden-md hidden-sm col-xs-12">
                                    <div className="single-footer-widget">
                                        <h3>quick links</h3>
                                        <ul className="footer-list">
                                            <li><a href="/">Home</a></li>
                                            <li><a href="team.html">Stuffs</a></li>
                                            <li><a href="room-grid.html">Suits &amp; Rooms</a></li>
                                            <li><a href="event.html">Event</a></li>
                                            <li><a href="location.html">Location</a></li>
                                            <li><a href="contact.html">Contact</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="single-footer-widget">
                                        <h3>collections</h3>
                                        <div className="instagram-image">
                                            <div className="footer-img">
                                                <a href="room-grid.html"><img src="img/footer/1.jpg" alt="" /></a>
                                            </div>
                                            <div className="footer-img">
                                                <a href="room-grid.html"><img src="img/footer/2.jpg" alt="" /></a>
                                            </div>
                                            <div className="footer-img">
                                                <a href="room-grid.html"><img src="img/footer/3.jpg" alt="" /></a>
                                            </div>
                                            <div className="footer-img">
                                                <a href="room-grid.html"><img src="img/footer/4.jpg" alt="" /></a>
                                            </div>
                                            <div className="footer-img">
                                                <a href="room-grid.html"><img src="img/footer/5.jpg" alt="" /></a>
                                            </div>
                                            <div className="footer-img">
                                                <a href="room-grid.html"><img src="img/footer/6.jpg" alt="" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer Widget End */}
                    {/* Footer Bottom Area Start */}
                    <div className="footer-bottom-area bg-black">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="footer-text text-center">
                                        <span>With <i className="fa fa-heart text-danger" /><a href="https://hasthemes.com/"><b> by Stonedage Tosh © 2019. All Rights Reserved.</b></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer Bottom Area End */}
                </footer>
            </div>
        </>

    );
}

export default App;
