import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "./config";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";


const Router = () => {
  return (
    <Suspense fallback={null}>
      {/* <GlobalStyles /> */}
      {/* <StickyButtons /> */}
      <Switch>
        {routes.map((routeItem) => {
          return routeItem.public ? (
            <PublicRoute
              key={routeItem.component}
              restricted={routeItem.restricted}
              path={routeItem.path}
              exact={routeItem.exact}
              role={routeItem.role}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          ) : (
            <PrivateRoute
              key={routeItem.component}
              restricted={routeItem.restricted}
              path={routeItem.path}
              exact={routeItem.exact}
              role={routeItem.role}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Router;
