const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    restricted: false,
    public: true,
    role: ['user', 'admin']
  },
  {
    path: ["/admin"],
    exact: false,
    component: "Admin",
    restricted: false,
    public: false,
    role: ['admin']
  },
  {
    path: ["/login"],
    exact: false,
    component: "Login",
    restricted: true,
    public: true,
    role: ['user', 'admin']
  },
  {
    path: ["/about"],
    exact: false,
    component: "About",
    restricted: false,
    public: true,
    role: ['user', 'admin']
  },
  {
    path: ["/rooms"],
    exact: false,
    component: "Rooms",
    restricted: false,
    public: true,
    role: ['user', 'admin']
  },
  {
    path: ["/deluxeRoom"],
    exact: false,
    component: "DeluxeRoom",
    restricted: false,
    public: true,
    role: ['user', 'admin']
  },
  {
    path: ["/familyRoom"],
    exact: false,
    component: "FamilyRoom",
    restricted: false,
    public: true,
    role: ['user', 'admin']
  },
  {
    path: ["/gallery"],
    exact: false,
    component: "Gallery",
    restricted: false,
    public: true,
    role: ['user', 'admin']
  },
];

export default routes;
